.form .wpcf7{
  background-color: $border;
  padding: 30px;
  border-radius: 10px;
  color: $white;
}
.wpcf7-form{
  label{
    width: 100%;
  }
  input {
    width: 100%;
    height: 50px;
    margin: 0 0 15px;
    vertical-align: middle;
    border: 3px solid $form-bg;
    font-size: 16px;
    font-weight: 300;
    line-height: 50px;
    color: $form-text;
    background-color: $form-bg;
    border-radius: 15px;
    box-shadow: none;
    padding: 0 1rem;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    &::placeholder{
      color: $form-text;
    }
  }
  textarea {
    width: 100%;
    height: 200px;
    margin: 0 0 15px;
    vertical-align: middle;
    border: 3px solid $form-bg;
    font-size: 16px;
    font-weight: 300;
    line-height: 50px;
    color: $form-text;
    background-color: $form-bg;
    border-radius: 15px;
    box-shadow: none;
    padding: 0 1rem;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    &::placeholder{
      color: $form-text;
    }
  }
  .wpcf7-submit {
    height: auto;
    margin: 20px 0 0 0;
    vertical-align: middle;
    background: $bg-primary none repeat scroll 0 0;
    border: 3px solid $white;
    font-size: 16px;
    line-height: 50px;
    color: $white;
    border-radius: 15px;
    box-shadow: none;
    padding: 0 25px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 100px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      background: $border none repeat scroll 0 0;
      color: $white; }
  }
}




.wpcf7 input[type="text"]:focus, .wpcf7 input[type="email"]:focus,
.wpcf7 input[type="tel"]:focus, .wpcf7 textarea:focus, .wpcf7 textarea.wpcf7-textarea:focus {
  outline: 0 none;
  background: $white none repeat scroll 0 0;
  border: 3px solid $border;
  box-shadow: none;
  color: $text;
}

.form-order {
  .wpcf7{
    padding: 15px;
    &-form{
      label{
        margin-bottom: 15px;
      }
      input[type=checkbox]{
        flex: 0 0 20px;
        width: 20px;
        height: 20px;
        margin: 0 15px 0 0;
        padding: 0;
        line-height: inherit;
        border: none;
      }
    }
  }
  .oferta-list2{
    width: 100%;
    span.wpcf7-form-control{
      display: flex;
      flex-flow: column wrap;
      margin: 0 -15px;
      @media (min-width: 768px){
        flex-flow: row wrap;
      }
      span.wpcf7-list-item{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 25%;
        margin: 0;
        padding: 15px;
        @media (min-width: 320px){
          width: 100%;
        }
        @media (min-width: 768px){
          width: 50%;
        }
        @media (min-width: 992px){
          width: 33.3333%;
        }
        @media (min-width: 1040px){
          width: 25%;
        }
      }
    }
  }
}


.box_lead{
  @include transition();
  h3{
    @include transition();
  }
  p{
    @include transition();
  }
  &:hover{
    background-color: $heading;
    cursor: pointer;

    h3{
      color: $white;
    }
    p{
      color: $white;
    }
  }
}