.single-praca{
  .entry{
    p{
      margin: 0 0 20px;
    }
    ul{
      padding-left: 20px;
      margin: 0 0 20px;
      li{
        list-style-type: disc;
      }
    }
  }
}