.header{
  position: fixed;
  display: block;
  width: 100%;
  top: 40px;
  z-index: 99;
}
//.header-r15{
//  border-radius: 15px;
//  overflow: hidden;
//}

.logo{
  display: block;
  flex: 0 0 auto;
  border-radius: 15px 0 0 15px;
  overflow: hidden;
  img{
    display: block;
    width: 100%;
    height: auto;
  }
}
.header_right{
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 100%;
  background-color: $white;
  border-radius: 0 15px 15px 0;
}
.header__top{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}
.header__bottom{
  display: flex;
  flex: 1;
  background-color: #ebebeb;
  border-radius: 0 0 15px 0;
}

.header__box--contact{
  display: flex;
  flex-flow:  row nowrap;
  margin-right: 15px;
}
.hb--mail,.hb--phone {
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: $heading;
}
.hb--mail{
  &::before{
    content: '';
    display: inline-block;
    background-image: url('images/icon_mail.jpg');
    background-position: center;
    background-repeat: no-repeat;
    width: 13px;
    height: 16px;
    margin-right: 5px;
  }
}
.hb--phone{
  &::before{
    content: '';
    display: inline-block;
    background-image: url(images/icon_phone.jpg);
    background-position: center;
    background-repeat: no-repeat;
    width: 13px;
    height: 16px;
    margin-right: 5px;
  }
}