html {
  font-size: 100%;
  overflow-x: hidden;
} /*16px*/

body {
  background-color: white;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  line-height: 1.333;
  color: $text;
  overflow-x: hidden;
}

//p {margin-bottom: 1.15rem;}

h1, h2, h3, h4, h5 {
  font-family: 'Noto Sans', sans-serif;
  font-weight: 600;
  line-height: 1.15;
}

h1 {
  font-weight: 600;
  margin-top: 0;
  font-size: 6.5625em;
}

h2 {
  font-size: 2.25em;
  margin-bottom: 55px;
}
strong{
  font-weight: 700;
}
p{
  margin-bottom: 35px;
}


small, .text_small {font-size: 0.8em;}

.text-center{
  text-align: center;
}