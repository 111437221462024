.footer{
  padding-top: 50px;
  border-top: 1px solid $border;
  &-col{
    margin-bottom: 35px;
    p:last-child{
      margin-bottom: 0;
    }
  }
  &--h3{
    font-weight: bold;
    margin-bottom: 25px;
  }
  ul{
    list-style-type: none;
    li{
      a{
        color: $text;
        text-decoration: none;
        &:hover{
          color: $text-red;
        }
      }
    }
  }
}

.footer--bottom{
  border-top: 1px solid $border;
  padding: 15px 0;
}