@import "info";
@import "variables";
@import "reset";
html, body{
  margin: 0;
  padding: 0;
}
*, *::before, *::after {
  box-sizing: border-box;
}
a{
  @include transition();
}
.p-relative{
  position: relative;
}
img{
  display: block;
  width: 100%;
  height: auto;
}

.box--rounded{
  border-radius: 15px;
  box-shadow: 0px 15px 35px 1px rgba(51,78,104,0.1);
}
.box--shadow{
  box-shadow: 0px 15px 35px 1px rgba(51,78,104,0.1);
}
.z-index-9{
  z-index: 9;
}

section{
  padding-bottom: 100px;
}

.bg-white{
  background-color: $white;
}



@import "typo";
@import "grid";

@import "nav";

@import "header";
@import "footer";
@import "form";

.main{
  padding-bottom: 80px;
}

.banner{
  background-image: url("images/bg-header-img.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 670px;
  &::before{
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 29, 60, 0.7);
  }
}
.banner__bg{
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.banner__wrapper{
  position: relative;
  display: block;
  width: 630px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 240px auto 0;
  h1{
    font-size: 1.75rem;
    color: $white;
    text-align: center;
    margin-bottom: 20px;
    @media (min-width: 768px){
      font-size: 2.325rem;
      margin-bottom: 40px;
    }
  }
  p{
    color: white;
    text-align: center;
  }
}

.header{

}

.home__category{
  margin-top: -210px;
}
.home__cat--wrapper div .home__cat::after{
  position: absolute;
  display: none;
  content: '';
  top: 20px;
  right: 0;
  width:0;
  height: calc(100% - 40px);
  border-right: 1px solid $border;
}
.home__cat--wrapper div:nth-child(2) .home__cat::after{
  display: none;
}
.home__cat--wrapper div:last-child .home__cat::after{
  display: none;
}
@media (min-width: 768px) {
  .home__cat--wrapper div:nth-child(1) .home__cat::after, .home__cat--wrapper div:nth-child(3) .home__cat::after{
    display: block;
  }
}
@media (min-width: 992px) {
  .home__cat--wrapper div:nth-child(2) .home__cat::after{
    display: block;
  }
}
.home__cat{
  padding: 20px 20px 30px;
  &--img{
    display: block;
    margin-bottom: 15px;
    img{
      display: block;
      margin: 0 auto;
    }
  }
  &--title{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  &--text{
    line-height: 1.5;
    text-align: center;
  }
}

.welcome{
  position: relative;
}
.welcome__wrapper{
  position: relative;
}
.welcome__box{
  position: relative;
  margin-top: -70px;
  padding: 20px 25px;
  z-index: 2;
  @media (min-width: 900px) {
    padding: 70px 50px ;
  }
  @media (min-width: 992px) {
    margin-top: 150px;
  }
  &--heading{
    font-size: 26px;
    font-weight: bold;
    line-height: 1.5;
    color: $heading;
    margin-bottom: 15px;
    @media (min-width: 992px) {
      font-size: 32px;
      margin-bottom: 50px;
    }
  }
  &--p{
    font-size: 18px;
    line-height: 1.4285;
    margin-bottom: 0;
    color: $text;
  }
}
.welcome__img{
  position: relative;
  width: 100%;
  height: auto;
  @media (min-width: 992px) {
    position: absolute;
    top: 0;
    right: 15px;
    width: unset;
    max-width: 100%;
  }
}



.leader{
  padding: 30px 0 160px;
  background-color: $bg-primary;
}
.leader-heading{
  margin-top: 80px;
  margin-bottom: 80px;
}
.lead--h2{
  font-size: 1.5em;
  color: $white;
  line-height: 1.5;
  border-right: none;
  border-bottom: 1px solid $white ;
  padding-right: 0;
  padding-bottom: 15px;
  margin-bottom: 15px;
  text-align: center;
  br{
    display: none;
  }
  @media (min-width: 768px) {
    font-size: 2.25em;
    border-right: 1px solid $white ;
    border-bottom: none;
    padding-right: 15px;
    padding-bottom: 0;
    margin-bottom: 0;
    text-align: left;
    br{
      display: block;
    }
  }

}
.lead--p{
  color: $white;
  margin-bottom: 0;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
}

.leader--container{
  padding: 0;
  @media (min-width: 1275px) {
    padding: 0 65px;
  }
}

.box_lead{
  padding: 15px;
  height: calc(100% - 30px);
  margin-bottom: 30px;
  @media (min-width: 1275px) {
    padding: 40px;
  }
  &--icon{
    margin-bottom: 20px;
    img{
      display: block;
      width: unset;
      max-width: 100%;
    }
  }
  &--h3{
    font-size: 19px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
    color: $heading;
  }
  p{
    font-size: 13px;
    margin-bottom: 0;
  }
}




.count{
  position: relative;
  display: block;
  margin-top: -96px;
  padding-bottom: 0;
}
.box--count{
  padding: 50px 15px;
}
.count--number{
  font-size: 50px;
  font-weight: bold;
  color: $text-red;
  text-align: center;
  line-height: 1;
}
.count-text{
  color: $heading;
  text-align: center;
  margin-bottom: 0;
}



/* page header bredcrumbs */

.page__top{
  background-image: url("images/bg-header-img.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 440px;
  margin-bottom: 50px;
  &::before{
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 29, 60, 0.7);
  }
}

.page__top--bg{
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.page__top--wrapper{
  position: relative;
  display: block;
  width: 90%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 240px auto ;
  h1{
    font-size: 1.75rem;
    color: $white;
    text-align: center;
    margin-bottom: 20px;
    @media (min-width: 768px){
      font-size: 2.325rem;
      margin-bottom: 40px;
    }
  }
}
.breadcrumbs{
  p{
    color: white;
    text-align: center;
  }
  a {
    color: white;
    text-decoration: none;
  }
}

/* kontakt */
.box-contact{
  height: calc(100% - 35px);
  margin-bottom: 35px;
  h3{
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  p{
    margin-bottom: 0;
  }
}
.box-p-50-70{
  padding: 20px;
  @media (min-width: 992px) {
    padding: 70px 50px;
  }
}

.gwarancje{
  background-color: $heading;
  padding: 100px 0;
  &--title{
    font-size: 1.5em;
    color: $white;
    line-height: 1.5;
    border-right: none;
    border-bottom: 1px solid $white ;
    padding-right: 0;
    padding-bottom: 15px;
    margin-bottom: 35px;
    text-align: center;
    @media (min-width: 991px) {
      font-size: 2.25em;
      border-right: 1px solid $white ;
      border-bottom: none;
      padding-right: 15px;
      padding-bottom: 0;
      margin-bottom: 0;
      text-align: left;
    }
  }
  p{
    color: $white;
    margin-bottom: 35px;
    text-align: center;
    @media (min-width: 991px) {
      margin-bottom: 0;
      text-align: left;
    }
  }
}
.btn{
  border-radius: 15px;
  &--white{
    color: $white;
    border-color: $white;
  }
}
.btn--contact{
  display: block;
  width: 280px;
  margin: 0 auto;
  border: 1px solid;
  padding: 15px;
  text-align: center;
  @media (min-width: 991px) {
    width: 100%;
  }
}

.contact__map{
  display: block;
  height: 470px;
}

/* single */
.single__header{}
.single__box--img{
  margin-bottom: 35px;
}
.single__img{}

.single__header--box{
  padding: 0;
  @media (min-width: 992px) {
    padding: 0 60px;
  }
}
/*.single__icon{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  margin-bottom: 35px;
  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}*/
.single__icon{
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 15px;
  margin-bottom: 35px;
  img{
    display: block;
    width: auto;
    height: 54px;
  }
}
.single__h2{
  font-size: 1.5rem;
  font-weight: bold;
  color: $heading;
  margin-bottom: 40px;
}

.single__desc{
  p{
    margin: 0 0 20px;
  }
  ul{
    padding-left: 20px;
    margin: 0 0 20px;
    li{
      list-style-type: disc;
    }
  }
}

.single__table{}

table{
  width: 100%;
}
thead{
  background-color: $heading;
  color: $white;
}

tbody tr:nth-child(even){
  background-color: #e6e9ed;
}

tr{
  border-bottom: 1px solid $heading;
}
th:nth-child(1), th:nth-child(3), th:nth-child(4),td:nth-child(1), td:nth-child(3), td:nth-child(4){
  width: 16.6666%;
}

td, th{
  padding: 25px 10px;
  text-align: center;
  vertical-align: middle;
}
th:nth-child(2), td:nth-child(2){
  width: 50.002%;
  text-align: left;
}
td:nth-child(1), td:nth-child(3), td:nth-child(4){
  font-weight: bold;
}
td{
  line-height: 1.5;
}

.single__title--sec{
  font-size: 1.5rem;
  color: $heading;
  line-height: 1.5;
}
.single__gallery--grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  img{
    box-shadow: 0px 15px 35px 1px rgba(51,78,104,0.1);
  }
}

.single__related{}
.related__item{
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 35px;
}
.related__img{
  display: block;
  flex: 0 0 160px;
  width: 160px;
  height: 160px;
}
.related__title--box{
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  align-items: center;
  a{
    display: block;
    font-size: 1.3125rem;
    font-weight: bold;
    color: $heading;
    text-decoration: none;
    margin-left: 30px;
    &:hover{
      color: $text-red;
    }
  }
}

.subcat__menu{
  background-color: $heading;
  padding: 30px;
  margin-bottom: 35px;
  li{
    color: $white;
    border-bottom: 1px solid $white;
    padding: 15px 5px;
    a{
      color: $white;
      text-decoration: none;
      @include transition();
      &:hover{
        padding-left: 10px;
      }
    }
    &.current-cat{
      padding: 15px 10px;
      background-color: $white;
      color: $heading;
      a{
        color: $heading;
      }
    }
  }
}

.cn-button.bootstrap.btn--approve {
  color: #fff;
  background: #d02729;
}


@import "praca";