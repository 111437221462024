$primary-color: #404041;
$secondary-color: #d93077;

$heading: #141d3c;
$text: #506a85;
$text-red: #d02729;

$bg-primary: #141d3c;


$border: #e6e9ed;

$white: #ffffff;

$form-bg: #f8f7f7;
$form-text: #b3b3b3;

@mixin  transition($time:0.5s){
  transition: all $time ease;
}
