$primaryColor: #021320; // dark blue
$secondaryColor: #0F9962; // green
$tertiaryColor: #005292; // light blue

$flex_nav_hoverColor:darken($secondaryColor,5%);
$flex_nav_borderColor: darken($secondaryColor,10%);
$flex_nav_linkColor: white;
$flex_nav_hotdogsColor: white;

$flex_nav_subNavWidth: 12.5em;
$flex_nav_breakpoint: 75em;

body {
  -webkit-animation: bugfix infinite 1s; /* needed for checkbox hack */
}
@-webkit-keyframes bugfix { from {padding:0;} to {padding:0;} } /* needed for checkbox hack */

#nav {
  position:relative;
  width: 100%;
  ul {
    position: absolute;
    display:none;
    width:100%;
    list-style:none;
    margin:0px;
    padding:0px;
    background-color: #ebebeb;
    border-radius: 0 0 15px 0;
    li {
      a {
        display:block;
        padding:1em;
        color: $heading;
        text-decoration:none;
        &:hover {
          color: $text-red;
        }
      }
      &:last-of-type {
        a {
          border-right:0px;
        }
      }
      // 2nd level

      ul {
        background-color: #ebebeb;
        border-radius: 0 !important;
        li {
          background-color: #ebebeb;
          a {
            padding-left:1.5em;
          }
          ul {
            li {
              a {
                padding-left:3.125em;
              }
            }
          }
        }
      }

    } // li

  } // ul

  input.trigger {
    position: absolute;
    top: -9999px;
    left: -9999px;
    &:checked ~ ul,&:checked ~ ul li ul {
      display:block !important;
      @media (min-width:$flex_nav_breakpoint){
        /* older flexbox */
        display: -webkit-box;
        display: -moz-box;
        display: box;
        -webkit-box-orient: horizontal;
        -moz-box-orient: horizontal;
        box-orient: horizontal;

        /* newer flexbox */
        display: flex;
        flex-direction: row;
      }
    }
  }
  label {
    position:relative;
    display:block;
    min-height:2em;
    padding:.45em;
    font-size:1.1em;
    margin:0;
    cursor:pointer;
    line-height: 2em;
    color:lighten($primaryColor,80%);
  }

  label:after {
    position: absolute;
    right: 1em;
    top: .2em;
    content:"\2261";
    font-size:1.8em;
    color:$flex_nav_hotdogsColor;
  }

  @media(min-width:$flex_nav_breakpoint){
    ul {
      position: relative;
      /* older flexbox */
      //display:block;
      display: box;

      box-orient: horizontal;

      /* newer flexbox */
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      height: 100%;

      li {
        position:relative;
        text-align: center;

        /* older flexbox */
        -ms-flex: 1;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        box-flex: 1;

        /* newer flexbox */
        flex: 1 1 auto;

        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        a{
          padding: 10px;
          text-transform: uppercase;
        }

        // 2nd level
        ul {
          display:none !important;
          position:absolute;
          top:3.0625em;
          left:0;
          display:block;
          width:$flex_nav_subNavWidth;
          z-index:200;
          li {
            display: block;
            text-align:left;
            height: auto;
            ul {
              z-index: 300;
              top:0px;
              left: ($flex_nav_subNavWidth - .1);
              li {
                a {
                  padding-left:30px !important;
                }
              }
            }
          }
        } // ul 2nd level

        &:hover > ul {
          display: block !important;
        }

      } // li
    } // ul
    label {
      display:none;
    }
  } // breakpoint

} // nav


/*.cat__link{
  text-align: center;
  text-decoration: none;
  .category__title--h2{
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5;
    color: $heading;
    text-decoration: none;
    text-align: center;
  }
  &:hover {
    text-decoration: none;
    .category__title--h2{
      color: $text-red;
      text-decoration: none;
    }
  }
}
.cat__img{
  margin-bottom: 20px;
}*/

.cat__link{
  display: flex;
  flex-flow: row nowrap;
  text-align: center;
  text-decoration: none;
  padding: 0 0 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid $border;
  .category__title--h2{
    display: flex;
    align-items: center;
    flex: 1 1 100%;
    margin-left: 30px;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5;
    color: $heading;
    text-decoration: none;
    text-align: left;
    margin-bottom: 0;
    @media (max-width: 768px){
      font-size: 1rem;
    }
  }
  &:hover {
    text-decoration: none;
    .category__title--h2{
      color: $text-red;
      text-decoration: none;
    }
  }
}
.cat__box--img{
  flex: 0 0 150px;
  overflow: hidden;
}
.cat__img{
  margin-bottom: 20px;
}

.single__doc{
  display: flex;
  flex-flow: column nowrap;
}
.single__doc--file{
  display: flex;
  flex-flow: row nowrap;
  padding: 10px;
  border-bottom: 1px solid $border;
  text-decoration: none;
  color: $heading;
  @include transition();
  &:last-child{
    border-bottom: none;
  }
  i{
    flex: 0 0 20px;
    font-size: 21px;
    color: $text-red;
  }
  span{
    padding-left: 5px;
    text-decoration: none;
    @include transition();
  }
  &:hover{
    background-color: $text-red;
    text-decoration: none;
    span, i{
      color: $white;
    }
  }
}
.single__doc__items{
  margin-bottom: 10px;
}
.single__doc--title{
  background-color: $border;
  color: $heading;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 15px;
  margin-bottom: 0;
}

#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#cssmenu {
  width: 100%;
  color: #ffffff;
}
#cssmenu ul ul {
  display: none;
}
.align-right {
  float: right;
}
#cssmenu > ul > li > a {
  padding: 15px 20px;
  border-left: 1px solid $white;
  border-right: 1px solid $white;
  border-top: 1px solid $white;
  cursor: pointer;
  z-index: 2;
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
  background: $heading;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
}

#cssmenu > ul > li > a:hover,
#cssmenu > ul > li.active > a,
#cssmenu > ul > li.open > a {
  color: #eeeeee;
  background: $text-red;
}
#cssmenu > ul > li.open > a {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid $white;
}
#cssmenu > ul > li:last-child > a,
#cssmenu > ul > li.last > a {
  border-bottom: 1px solid $white;
}
.holder {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: 0;
}
.holder::after,
.holder::before {
  display: block;
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  right: 20px;
  z-index: 10;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.holder::after {
  top: 17px;
  border-top: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
}
#cssmenu > ul > li > a:hover > span::after,
#cssmenu > ul > li.active > a > span::after,
#cssmenu > ul > li.open > a > span::after {
  border-color: #eeeeee;
}
.holder::before {
  top: 18px;
  border-top: 2px solid;
  border-left: 2px solid;
  border-top-color: inherit;
  border-left-color: inherit;
}
#cssmenu ul ul li a {
  cursor: pointer;
  border-bottom: 1px solid $white;
  border-left: 1px solid $white;
  border-right: 1px solid $white;
  padding: 10px 20px;
  z-index: 1;
  text-decoration: none;
  color: $heading;
  background: $white;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}
#cssmenu ul ul li:hover > a,
#cssmenu ul ul li.open > a,
#cssmenu ul ul li.active > a {
  background: $text-red;
  color: #ffffff;
}
#cssmenu ul ul li:first-child > a {
  box-shadow: none;
}
#cssmenu ul ul ul li:first-child > a {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}
#cssmenu ul ul ul li a {
  padding-left: 30px;
}
#cssmenu > ul > li > ul > li:last-child > a,
#cssmenu > ul > li > ul > li.last > a {
  border-bottom: 0;
}
#cssmenu > ul > li > ul > li.open:last-child > a,
#cssmenu > ul > li > ul > li.last.open > a {
  border-bottom: 1px solid #32373e;
}
#cssmenu > ul > li > ul > li.open:last-child > ul > li:last-child > a {
  border-bottom: 0;
}
#cssmenu ul ul li.has-sub > a::after {
  display: block;
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  right: 20px;
  z-index: 10;
  top: 11.5px;
  border-top: 2px solid #eeeeee;
  border-left: 2px solid #eeeeee;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
#cssmenu ul ul li.active > a::after,
#cssmenu ul ul li.open > a::after,
#cssmenu ul ul li > a:hover::after {
  border-color: #ffffff;
}
