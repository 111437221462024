@charset "UTF-8";
/*!
Theme Name: Artmet 1.0
Theme URI:
Author: Boguslaw Wrobel;
Author URI:
Description: artmet theme
Version: 1.0

*/
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html, body {
  margin: 0;
  padding: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}

a {
  transition: all 0.5s ease;
}

.p-relative {
  position: relative;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

.box--rounded {
  border-radius: 15px;
  box-shadow: 0px 15px 35px 1px rgba(51, 78, 104, 0.1);
}

.box--shadow {
  box-shadow: 0px 15px 35px 1px rgba(51, 78, 104, 0.1);
}

.z-index-9 {
  z-index: 9;
}

section {
  padding-bottom: 100px;
}

.bg-white {
  background-color: #ffffff;
}

html {
  font-size: 100%;
  overflow-x: hidden;
} /*16px*/
body {
  background-color: white;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  line-height: 1.333;
  color: #506a85;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5 {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  line-height: 1.15;
}

h1 {
  font-weight: 600;
  margin-top: 0;
  font-size: 6.5625em;
}

h2 {
  font-size: 2.25em;
  margin-bottom: 55px;
}

strong {
  font-weight: 700;
}

p {
  margin-bottom: 35px;
}

small, .text_small {
  font-size: 0.8em;
}

.text-center {
  text-align: center;
}

section {
  position: relative;
}

/*.container{
  position: relative;
  display: block;
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.container-full{
  position: relative;
  display: block;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row{
  display: flex;
  flex-flow: row nowrap;
  margin-left: -15px;
  margin-right: -15px;
}
.d-flex{
  display: flex;
}

.f-col{
  flex: 1 1 100%;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}*/
body {
  -webkit-animation: bugfix infinite 1s; /* needed for checkbox hack */
}

@-webkit-keyframes bugfix {
  from {
    padding: 0;
  }
  to {
    padding: 0;
  }
} /* needed for checkbox hack */
#nav {
  position: relative;
  width: 100%;
}
#nav ul {
  position: absolute;
  display: none;
  width: 100%;
  list-style: none;
  margin: 0px;
  padding: 0px;
  background-color: #ebebeb;
  border-radius: 0 0 15px 0;
}
#nav ul li a {
  display: block;
  padding: 1em;
  color: #141d3c;
  text-decoration: none;
}
#nav ul li a:hover {
  color: #d02729;
}
#nav ul li:last-of-type a {
  border-right: 0px;
}
#nav ul li ul {
  background-color: #ebebeb;
  border-radius: 0 !important;
}
#nav ul li ul li {
  background-color: #ebebeb;
}
#nav ul li ul li a {
  padding-left: 1.5em;
}
#nav ul li ul li ul li a {
  padding-left: 3.125em;
}
#nav input.trigger {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
#nav input.trigger:checked ~ ul, #nav input.trigger:checked ~ ul li ul {
  display: block !important;
}
@media (min-width: 75em) {
  #nav input.trigger:checked ~ ul, #nav input.trigger:checked ~ ul li ul {
    /* older flexbox */
    display: -webkit-box;
    display: -moz-box;
    display: box;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    /* newer flexbox */
    display: flex;
    flex-direction: row;
  }
}
#nav label {
  position: relative;
  display: block;
  min-height: 2em;
  padding: 0.45em;
  font-size: 1.1em;
  margin: 0;
  cursor: pointer;
  line-height: 2em;
  color: #bfe1fb;
}
#nav label:after {
  position: absolute;
  right: 1em;
  top: 0.2em;
  content: "≡";
  font-size: 1.8em;
  color: white;
}
@media (min-width: 75em) {
  #nav ul {
    position: relative;
    /* older flexbox */
    display: box;
    box-orient: horizontal;
    /* newer flexbox */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  #nav ul li {
    position: relative;
    text-align: center;
    /* older flexbox */
    -ms-flex: 1;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    /* newer flexbox */
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  #nav ul li a {
    padding: 10px;
    text-transform: uppercase;
  }
  #nav ul li ul {
    display: none !important;
    position: absolute;
    top: 3.0625em;
    left: 0;
    display: block;
    width: 12.5em;
    z-index: 200;
  }
  #nav ul li ul li {
    display: block;
    text-align: left;
    height: auto;
  }
  #nav ul li ul li ul {
    z-index: 300;
    top: 0px;
    left: 12.4em;
  }
  #nav ul li ul li ul li a {
    padding-left: 30px !important;
  }
  #nav ul li:hover > ul {
    display: block !important;
  }
  #nav label {
    display: none;
  }
}

/*.cat__link{
  text-align: center;
  text-decoration: none;
  .category__title--h2{
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5;
    color: $heading;
    text-decoration: none;
    text-align: center;
  }
  &:hover {
    text-decoration: none;
    .category__title--h2{
      color: $text-red;
      text-decoration: none;
    }
  }
}
.cat__img{
  margin-bottom: 20px;
}*/
.cat__link {
  display: flex;
  flex-flow: row nowrap;
  text-align: center;
  text-decoration: none;
  padding: 0 0 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e6e9ed;
}
.cat__link .category__title--h2 {
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  margin-left: 30px;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.5;
  color: #141d3c;
  text-decoration: none;
  text-align: left;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .cat__link .category__title--h2 {
    font-size: 1rem;
  }
}
.cat__link:hover {
  text-decoration: none;
}
.cat__link:hover .category__title--h2 {
  color: #d02729;
  text-decoration: none;
}

.cat__box--img {
  flex: 0 0 150px;
  overflow: hidden;
}

.cat__img {
  margin-bottom: 20px;
}

.single__doc {
  display: flex;
  flex-flow: column nowrap;
}

.single__doc--file {
  display: flex;
  flex-flow: row nowrap;
  padding: 10px;
  border-bottom: 1px solid #e6e9ed;
  text-decoration: none;
  color: #141d3c;
  transition: all 0.5s ease;
}
.single__doc--file:last-child {
  border-bottom: none;
}
.single__doc--file i {
  flex: 0 0 20px;
  font-size: 21px;
  color: #d02729;
}
.single__doc--file span {
  padding-left: 5px;
  text-decoration: none;
  transition: all 0.5s ease;
}
.single__doc--file:hover {
  background-color: #d02729;
  text-decoration: none;
}
.single__doc--file:hover span, .single__doc--file:hover i {
  color: #ffffff;
}

.single__doc__items {
  margin-bottom: 10px;
}

.single__doc--title {
  background-color: #e6e9ed;
  color: #141d3c;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 15px;
  margin-bottom: 0;
}

#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#cssmenu {
  width: 100%;
  color: #ffffff;
}

#cssmenu ul ul {
  display: none;
}

.align-right {
  float: right;
}

#cssmenu > ul > li > a {
  padding: 15px 20px;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
  cursor: pointer;
  z-index: 2;
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
  background: #141d3c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
}

#cssmenu > ul > li > a:hover,
#cssmenu > ul > li.active > a,
#cssmenu > ul > li.open > a {
  color: #eeeeee;
  background: #d02729;
}

#cssmenu > ul > li.open > a {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid #ffffff;
}

#cssmenu > ul > li:last-child > a,
#cssmenu > ul > li.last > a {
  border-bottom: 1px solid #ffffff;
}

.holder {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.holder::after,
.holder::before {
  display: block;
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  right: 20px;
  z-index: 10;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.holder::after {
  top: 17px;
  border-top: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
}

#cssmenu > ul > li > a:hover > span::after,
#cssmenu > ul > li.active > a > span::after,
#cssmenu > ul > li.open > a > span::after {
  border-color: #eeeeee;
}

.holder::before {
  top: 18px;
  border-top: 2px solid;
  border-left: 2px solid;
  border-top-color: inherit;
  border-left-color: inherit;
}

#cssmenu ul ul li a {
  cursor: pointer;
  border-bottom: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  padding: 10px 20px;
  z-index: 1;
  text-decoration: none;
  color: #141d3c;
  background: #ffffff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

#cssmenu ul ul li:hover > a,
#cssmenu ul ul li.open > a,
#cssmenu ul ul li.active > a {
  background: #d02729;
  color: #ffffff;
}

#cssmenu ul ul li:first-child > a {
  box-shadow: none;
}

#cssmenu ul ul ul li:first-child > a {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

#cssmenu ul ul ul li a {
  padding-left: 30px;
}

#cssmenu > ul > li > ul > li:last-child > a,
#cssmenu > ul > li > ul > li.last > a {
  border-bottom: 0;
}

#cssmenu > ul > li > ul > li.open:last-child > a,
#cssmenu > ul > li > ul > li.last.open > a {
  border-bottom: 1px solid #32373e;
}

#cssmenu > ul > li > ul > li.open:last-child > ul > li:last-child > a {
  border-bottom: 0;
}

#cssmenu ul ul li.has-sub > a::after {
  display: block;
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  right: 20px;
  z-index: 10;
  top: 11.5px;
  border-top: 2px solid #eeeeee;
  border-left: 2px solid #eeeeee;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

#cssmenu ul ul li.active > a::after,
#cssmenu ul ul li.open > a::after,
#cssmenu ul ul li > a:hover::after {
  border-color: #ffffff;
}

.header {
  position: fixed;
  display: block;
  width: 100%;
  top: 40px;
  z-index: 99;
}

.logo {
  display: block;
  flex: 0 0 auto;
  border-radius: 15px 0 0 15px;
  overflow: hidden;
}
.logo img {
  display: block;
  width: 100%;
  height: auto;
}

.header_right {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 100%;
  background-color: #ffffff;
  border-radius: 0 15px 15px 0;
}

.header__top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.header__bottom {
  display: flex;
  flex: 1;
  background-color: #ebebeb;
  border-radius: 0 0 15px 0;
}

.header__box--contact {
  display: flex;
  flex-flow: row nowrap;
  margin-right: 15px;
}

.hb--mail, .hb--phone {
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: #141d3c;
}

.hb--mail::before {
  content: "";
  display: inline-block;
  background-image: url("images/icon_mail.jpg");
  background-position: center;
  background-repeat: no-repeat;
  width: 13px;
  height: 16px;
  margin-right: 5px;
}

.hb--phone::before {
  content: "";
  display: inline-block;
  background-image: url(images/icon_phone.jpg);
  background-position: center;
  background-repeat: no-repeat;
  width: 13px;
  height: 16px;
  margin-right: 5px;
}

.footer {
  padding-top: 50px;
  border-top: 1px solid #e6e9ed;
}
.footer-col {
  margin-bottom: 35px;
}
.footer-col p:last-child {
  margin-bottom: 0;
}
.footer--h3 {
  font-weight: bold;
  margin-bottom: 25px;
}
.footer ul {
  list-style-type: none;
}
.footer ul li a {
  color: #506a85;
  text-decoration: none;
}
.footer ul li a:hover {
  color: #d02729;
}

.footer--bottom {
  border-top: 1px solid #e6e9ed;
  padding: 15px 0;
}

.form .wpcf7 {
  background-color: #e6e9ed;
  padding: 30px;
  border-radius: 10px;
  color: #ffffff;
}

.wpcf7-form label {
  width: 100%;
}
.wpcf7-form input {
  width: 100%;
  height: 50px;
  margin: 0 0 15px;
  vertical-align: middle;
  border: 3px solid #f8f7f7;
  font-size: 16px;
  font-weight: 300;
  line-height: 50px;
  color: #b3b3b3;
  background-color: #f8f7f7;
  border-radius: 15px;
  box-shadow: none;
  padding: 0 1rem;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.wpcf7-form input::placeholder {
  color: #b3b3b3;
}
.wpcf7-form textarea {
  width: 100%;
  height: 200px;
  margin: 0 0 15px;
  vertical-align: middle;
  border: 3px solid #f8f7f7;
  font-size: 16px;
  font-weight: 300;
  line-height: 50px;
  color: #b3b3b3;
  background-color: #f8f7f7;
  border-radius: 15px;
  box-shadow: none;
  padding: 0 1rem;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.wpcf7-form textarea::placeholder {
  color: #b3b3b3;
}
.wpcf7-form .wpcf7-submit {
  height: auto;
  margin: 20px 0 0 0;
  vertical-align: middle;
  background: #141d3c none repeat scroll 0 0;
  border: 3px solid #ffffff;
  font-size: 16px;
  line-height: 50px;
  color: #ffffff;
  border-radius: 15px;
  box-shadow: none;
  padding: 0 25px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 100px;
  font-weight: bold;
  cursor: pointer;
}
.wpcf7-form .wpcf7-submit:hover {
  background: #e6e9ed none repeat scroll 0 0;
  color: #ffffff;
}

.wpcf7 input[type=text]:focus, .wpcf7 input[type=email]:focus,
.wpcf7 input[type=tel]:focus, .wpcf7 textarea:focus, .wpcf7 textarea.wpcf7-textarea:focus {
  outline: 0 none;
  background: #ffffff none repeat scroll 0 0;
  border: 3px solid #e6e9ed;
  box-shadow: none;
  color: #506a85;
}

.form-order .wpcf7 {
  padding: 15px;
}
.form-order .wpcf7-form label {
  margin-bottom: 15px;
}
.form-order .wpcf7-form input[type=checkbox] {
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  margin: 0 15px 0 0;
  padding: 0;
  line-height: inherit;
  border: none;
}
.form-order .oferta-list2 {
  width: 100%;
}
.form-order .oferta-list2 span.wpcf7-form-control {
  display: flex;
  flex-flow: column wrap;
  margin: 0 -15px;
}
@media (min-width: 768px) {
  .form-order .oferta-list2 span.wpcf7-form-control {
    flex-flow: row wrap;
  }
}
.form-order .oferta-list2 span.wpcf7-form-control span.wpcf7-list-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 25%;
  margin: 0;
  padding: 15px;
}
@media (min-width: 320px) {
  .form-order .oferta-list2 span.wpcf7-form-control span.wpcf7-list-item {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .form-order .oferta-list2 span.wpcf7-form-control span.wpcf7-list-item {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .form-order .oferta-list2 span.wpcf7-form-control span.wpcf7-list-item {
    width: 33.3333%;
  }
}
@media (min-width: 1040px) {
  .form-order .oferta-list2 span.wpcf7-form-control span.wpcf7-list-item {
    width: 25%;
  }
}

.box_lead {
  transition: all 0.5s ease;
}
.box_lead h3 {
  transition: all 0.5s ease;
}
.box_lead p {
  transition: all 0.5s ease;
}
.box_lead:hover {
  background-color: #141d3c;
  cursor: pointer;
}
.box_lead:hover h3 {
  color: #ffffff;
}
.box_lead:hover p {
  color: #ffffff;
}

.main {
  padding-bottom: 80px;
}

.banner {
  background-image: url("images/bg-header-img.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 670px;
}
.banner::before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 29, 60, 0.7);
}

.banner__bg {
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.banner__wrapper {
  position: relative;
  display: block;
  width: 630px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 240px auto 0;
}
.banner__wrapper h1 {
  font-size: 1.75rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .banner__wrapper h1 {
    font-size: 2.325rem;
    margin-bottom: 40px;
  }
}
.banner__wrapper p {
  color: white;
  text-align: center;
}

.home__category {
  margin-top: -210px;
}

.home__cat--wrapper div .home__cat::after {
  position: absolute;
  display: none;
  content: "";
  top: 20px;
  right: 0;
  width: 0;
  height: calc(100% - 40px);
  border-right: 1px solid #e6e9ed;
}

.home__cat--wrapper div:nth-child(2) .home__cat::after {
  display: none;
}

.home__cat--wrapper div:last-child .home__cat::after {
  display: none;
}

@media (min-width: 768px) {
  .home__cat--wrapper div:nth-child(1) .home__cat::after, .home__cat--wrapper div:nth-child(3) .home__cat::after {
    display: block;
  }
}
@media (min-width: 992px) {
  .home__cat--wrapper div:nth-child(2) .home__cat::after {
    display: block;
  }
}
.home__cat {
  padding: 20px 20px 30px;
}
.home__cat--img {
  display: block;
  margin-bottom: 15px;
}
.home__cat--img img {
  display: block;
  margin: 0 auto;
}
.home__cat--title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}
.home__cat--text {
  line-height: 1.5;
  text-align: center;
}

.welcome {
  position: relative;
}

.welcome__wrapper {
  position: relative;
}

.welcome__box {
  position: relative;
  margin-top: -70px;
  padding: 20px 25px;
  z-index: 2;
}
@media (min-width: 900px) {
  .welcome__box {
    padding: 70px 50px;
  }
}
@media (min-width: 992px) {
  .welcome__box {
    margin-top: 150px;
  }
}
.welcome__box--heading {
  font-size: 26px;
  font-weight: bold;
  line-height: 1.5;
  color: #141d3c;
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .welcome__box--heading {
    font-size: 32px;
    margin-bottom: 50px;
  }
}
.welcome__box--p {
  font-size: 18px;
  line-height: 1.4285;
  margin-bottom: 0;
  color: #506a85;
}

.welcome__img {
  position: relative;
  width: 100%;
  height: auto;
}
@media (min-width: 992px) {
  .welcome__img {
    position: absolute;
    top: 0;
    right: 15px;
    width: unset;
    max-width: 100%;
  }
}

.leader {
  padding: 30px 0 160px;
  background-color: #141d3c;
}

.leader-heading {
  margin-top: 80px;
  margin-bottom: 80px;
}

.lead--h2 {
  font-size: 1.5em;
  color: #ffffff;
  line-height: 1.5;
  border-right: none;
  border-bottom: 1px solid #ffffff;
  padding-right: 0;
  padding-bottom: 15px;
  margin-bottom: 15px;
  text-align: center;
}
.lead--h2 br {
  display: none;
}
@media (min-width: 768px) {
  .lead--h2 {
    font-size: 2.25em;
    border-right: 1px solid #ffffff;
    border-bottom: none;
    padding-right: 15px;
    padding-bottom: 0;
    margin-bottom: 0;
    text-align: left;
  }
  .lead--h2 br {
    display: block;
  }
}

.lead--p {
  color: #ffffff;
  margin-bottom: 0;
  text-align: center;
}
@media (min-width: 768px) {
  .lead--p {
    text-align: left;
  }
}

.leader--container {
  padding: 0;
}
@media (min-width: 1275px) {
  .leader--container {
    padding: 0 65px;
  }
}

.box_lead {
  padding: 15px;
  height: calc(100% - 30px);
  margin-bottom: 30px;
}
@media (min-width: 1275px) {
  .box_lead {
    padding: 40px;
  }
}
.box_lead--icon {
  margin-bottom: 20px;
}
.box_lead--icon img {
  display: block;
  width: unset;
  max-width: 100%;
}
.box_lead--h3 {
  font-size: 19px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 20px;
  color: #141d3c;
}
.box_lead p {
  font-size: 13px;
  margin-bottom: 0;
}

.count {
  position: relative;
  display: block;
  margin-top: -96px;
  padding-bottom: 0;
}

.box--count {
  padding: 50px 15px;
}

.count--number {
  font-size: 50px;
  font-weight: bold;
  color: #d02729;
  text-align: center;
  line-height: 1;
}

.count-text {
  color: #141d3c;
  text-align: center;
  margin-bottom: 0;
}

/* page header bredcrumbs */
.page__top {
  background-image: url("images/bg-header-img.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 440px;
  margin-bottom: 50px;
}
.page__top::before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 29, 60, 0.7);
}

.page__top--bg {
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.page__top--wrapper {
  position: relative;
  display: block;
  width: 90%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 240px auto;
}
.page__top--wrapper h1 {
  font-size: 1.75rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .page__top--wrapper h1 {
    font-size: 2.325rem;
    margin-bottom: 40px;
  }
}

.breadcrumbs p {
  color: white;
  text-align: center;
}
.breadcrumbs a {
  color: white;
  text-decoration: none;
}

/* kontakt */
.box-contact {
  height: calc(100% - 35px);
  margin-bottom: 35px;
}
.box-contact h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}
.box-contact p {
  margin-bottom: 0;
}

.box-p-50-70 {
  padding: 20px;
}
@media (min-width: 992px) {
  .box-p-50-70 {
    padding: 70px 50px;
  }
}

.gwarancje {
  background-color: #141d3c;
  padding: 100px 0;
}
.gwarancje--title {
  font-size: 1.5em;
  color: #ffffff;
  line-height: 1.5;
  border-right: none;
  border-bottom: 1px solid #ffffff;
  padding-right: 0;
  padding-bottom: 15px;
  margin-bottom: 35px;
  text-align: center;
}
@media (min-width: 991px) {
  .gwarancje--title {
    font-size: 2.25em;
    border-right: 1px solid #ffffff;
    border-bottom: none;
    padding-right: 15px;
    padding-bottom: 0;
    margin-bottom: 0;
    text-align: left;
  }
}
.gwarancje p {
  color: #ffffff;
  margin-bottom: 35px;
  text-align: center;
}
@media (min-width: 991px) {
  .gwarancje p {
    margin-bottom: 0;
    text-align: left;
  }
}

.btn {
  border-radius: 15px;
}
.btn--white {
  color: #ffffff;
  border-color: #ffffff;
}

.btn--contact {
  display: block;
  width: 280px;
  margin: 0 auto;
  border: 1px solid;
  padding: 15px;
  text-align: center;
}
@media (min-width: 991px) {
  .btn--contact {
    width: 100%;
  }
}

.contact__map {
  display: block;
  height: 470px;
}

/* single */
.single__box--img {
  margin-bottom: 35px;
}

.single__header--box {
  padding: 0;
}
@media (min-width: 992px) {
  .single__header--box {
    padding: 0 60px;
  }
}

/*.single__icon{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  margin-bottom: 35px;
  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}*/
.single__icon {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 15px;
  margin-bottom: 35px;
}
.single__icon img {
  display: block;
  width: auto;
  height: 54px;
}

.single__h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #141d3c;
  margin-bottom: 40px;
}

.single__desc p {
  margin: 0 0 20px;
}
.single__desc ul {
  padding-left: 20px;
  margin: 0 0 20px;
}
.single__desc ul li {
  list-style-type: disc;
}

table {
  width: 100%;
}

thead {
  background-color: #141d3c;
  color: #ffffff;
}

tbody tr:nth-child(even) {
  background-color: #e6e9ed;
}

tr {
  border-bottom: 1px solid #141d3c;
}

th:nth-child(1), th:nth-child(3), th:nth-child(4), td:nth-child(1), td:nth-child(3), td:nth-child(4) {
  width: 16.6666%;
}

td, th {
  padding: 25px 10px;
  text-align: center;
  vertical-align: middle;
}

th:nth-child(2), td:nth-child(2) {
  width: 50.002%;
  text-align: left;
}

td:nth-child(1), td:nth-child(3), td:nth-child(4) {
  font-weight: bold;
}

td {
  line-height: 1.5;
}

.single__title--sec {
  font-size: 1.5rem;
  color: #141d3c;
  line-height: 1.5;
}

.single__gallery--grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}
.single__gallery--grid img {
  box-shadow: 0px 15px 35px 1px rgba(51, 78, 104, 0.1);
}

.related__item {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 35px;
}

.related__img {
  display: block;
  flex: 0 0 160px;
  width: 160px;
  height: 160px;
}

.related__title--box {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  align-items: center;
}
.related__title--box a {
  display: block;
  font-size: 1.3125rem;
  font-weight: bold;
  color: #141d3c;
  text-decoration: none;
  margin-left: 30px;
}
.related__title--box a:hover {
  color: #d02729;
}

.subcat__menu {
  background-color: #141d3c;
  padding: 30px;
  margin-bottom: 35px;
}
.subcat__menu li {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  padding: 15px 5px;
}
.subcat__menu li a {
  color: #ffffff;
  text-decoration: none;
  transition: all 0.5s ease;
}
.subcat__menu li a:hover {
  padding-left: 10px;
}
.subcat__menu li.current-cat {
  padding: 15px 10px;
  background-color: #ffffff;
  color: #141d3c;
}
.subcat__menu li.current-cat a {
  color: #141d3c;
}

.cn-button.bootstrap.btn--approve {
  color: #fff;
  background: #d02729;
}

.single-praca .entry p {
  margin: 0 0 20px;
}
.single-praca .entry ul {
  padding-left: 20px;
  margin: 0 0 20px;
}
.single-praca .entry ul li {
  list-style-type: disc;
}